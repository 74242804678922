import React, { Component } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import 'react-circular-progressbar/dist/styles.css';
import firebaseApp from './firebaseConfig';
import clickedButton from './clickedmic.png';
import micButton from './micbutton.png';
import stopButton from './stopbutton.png';
import playButton from './playbutton.png';
import WaveSurfer from 'wavesurfer.js';

var audioTrack;
var wavesurfer;

class Home extends Component {

  constructor() {
    super();
    this.state = {
      title: '',
      name: '',
      docId: '',
      allData: [],
      recordTime: 0,
      progressTime: '0:00',
      controlButton: "record",
      timerOn: false,
      timerStart: 0,
      timerTime: 0 ,
      circleProgress: 0,
      saved: false,
      copied: false
    };
  }

  componentDidMount() {
    audioTrack = new window.WebAudioTrack();
    wavesurfer = WaveSurfer.create({
      container: document.querySelector('#waveform'),
      barWidth: 5,
      barHeight: 1,
      barGap: 3,
      cursorColor: '#637796',
      waveColor: '#DBDBED',
      progressColor: '#637796'
    });

    wavesurfer.on('error', function (e) {
      console.warn(e);
    });

    // Show current time
    wavesurfer.on('audioprocess', () => {
      this.setState({progressTime: this.formatTime(wavesurfer.getCurrentTime())});
    });
  }
  
  formatTime = (time) => {
    return [
      Math.floor((time % 3600) / 60), // minutes
      ('00' + Math.floor(time % 60)).slice(-2) // seconds
    ].join(':');
  };

  startRec = () => {
    audioTrack.resumeRecording();
    audioTrack.startRecording(() => {
      this.startTimer();
    });
    this.setState({ controlButton: "recording", copied: false });
  };

  loadingCircle = () => {
    this.loadingTimer = setInterval(() => {
      if (this.state.saved) {
        clearInterval(this.loadingTimer);
      }
      this.setState({
        circleProgress: this.state.circleProgress + .25
      });
    }, 100);
  }
  startTimer = () => {
    this.setState({
      timerOn: true,
      timerTime: 0,
      timerStart: Date.now() - 0,
      circleProgress: 0
    });
    this.timer = setInterval(() => {
      if(this.state.timerTime > 59000){
        this.stopRec();
        this.stopTimer();
      }
      this.setState({
        timerTime: Date.now() - this.state.timerStart,
        circleProgress: this.state.circleProgress + .166
      });
    }, 100);
  };
  
  stopTimer = () => {
    this.setState({ timerOn: false });
    clearInterval(this.timer);
  };

  resetTimer = () => {
    this.setState({
      timerStart: 0,
      timerTime: 0
    });
  };
  

  stopRec = () => {
    audioTrack.stopRecording(() => {
      let recFile = audioTrack.getBlobSrc(); 
      console.log(audioTrack);
      let opus = audioTrack.getOpusBlob();
      console.log(opus);
      // Load audio in wavesurfer from URL
      wavesurfer.load(recFile);
      this.addData(recFile);
      // wavesurfer.load(opus); 
      // this.addData(opus);  
    });
    let recordTime = audioTrack.getRecordingTime().toFixed(2);
    this.stopTimer();
    this.setState({ recordTime, controlButton: "play" });
  };
  
  playRec = () => {
    // wavesurfer play button
    wavesurfer.playPause();
    this.setState({ controlButton: "stop" });
  };

  actionButton = () => {
    switch (this.state.controlButton) {
      case "play":
        this.playRec();
        wavesurfer.on('finish', (e) => {
          this.setState({ controlButton: "play" });
        });
        break;

      case "stop":
        wavesurfer.playPause();
        this.setState({ controlButton: "play" });
        break;

      case "record":
        this.startRec();
        break;
      
      case "recording":
        this.stopRec();
        break;

      default:
        this.startRec();
        break;
    }
  }
  controlButton() {
    switch (this.state.controlButton) {
      case "play":
          return playButton;
      
      case "stop":
          return stopButton;

      case "record":
        return micButton;
      
      case "recording":
        return clickedButton;

      default: 
        return micButton;
    }
  }
  cancelRec = () => {
    this.setState({
      controlButton: "record",
      timerTime: 0,
      circleProgress: 0,
      saved: false
    });
    this.deleteData();
  }
  
  updateInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  addData = (file) => {
    // e.preventDefault();
    this.loadingCircle();
    const db = firebaseApp.firestore();
    db.collection('speeks').add({
      title: this.state.title,
      author: this.state.name,
      length: this.state.recordTime
    }).then(ref => {
      this.setState({
        title: '',
        name: '',
        docId: ref.id,
        saved: true
      });
      let storageRef = firebaseApp.storage().ref();
      let newBlob = audioTrack.getBlob();
      const soundRef = storageRef.child('audio/' + ref.id + '/clip.wav');
      soundRef.put(newBlob).then(function (snapshot) {
        console.log('Uploaded audio');
      });
    });
    
  };

  getData = () => {
    const db = firebaseApp.firestore();
    var wholeData = []
    db.collection('speeks').orderBy('title', 'asc').get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          // console.log(doc.id, '=>', doc.data());
          // console.log(doc.data().name + doc.data().age);
          console.log(doc.data());
          wholeData.push(doc.data());
        });
        this.setState({ allData: wholeData });
      })
      .catch(error => {
        console.log('Error!', error);
      })
  }
  deleteData = () => {
    if (this.state.docId !== '') {
      let storageRef = firebaseApp.storage().ref();
      const soundRef = storageRef.child('audio/' + this.state.docId + '/clip.wav');
      soundRef.delete().then(function () {
        console.log('Deleted audio');
      }).catch(function (error) {
        console.log('Error deleting audio');
      });
    } else {
      console.log('File does not exist');
    }
  };
  render() {
    const timerTime  = this.state.timerTime;
    let seconds = ("0" + (Math.floor(timerTime / 1000) % 60)).slice(-2);
    let minutes = ("" + (Math.floor(timerTime / 60000) % 60)).slice(-2);
    
    var listOfData = this.state.allData.map((val, i) => {
      var title = val.title
      var author = val.author
      return (
        <li key={i}>{title} ({author})</li>
      )
    })

    return (
      <div>
        <div className="hero-text mb-2">
          Speak whatever's on your mind.&nbsp;<br className="d-md-block" />
          <span className="d-sm-block d-md-none d-lg-block d-xl-block">
            Then share it to the world.
          </span>
          {/* <form className="form-inline mt-sm-4" onSubmit={this.addData}>
            <input
              className="form-control border-input mr-sm-2"
              type="text"
              name="title"
              placeholder="Enter a title"
              onChange={this.updateInput}
              value={this.state.title}
            />
            <br />
            <input
              className="d-none form-control border-input"
              type="text"
              name="name"
              placeholder="Your name"
              onChange={this.updateInput}
              value={this.state.name}
            />
            <br />
            <button className="purp-btn" type="submit">SAVE</button>
          </form> */}
        </div>
        
        <img 
          alt="player button" 
          className="player-icon" 
          id="btn-play-recording" 
          onClick={() => this.actionButton()} 
          src={this.controlButton()} 
        />
        <div 
          className={this.state.controlButton === "recording" ? "progress-circle" : "d-none"}
          onClick={() => this.stopRec()}
        >
          <CircularProgressbar
            value={this.state.circleProgress}
            strokeWidth={4}
            styles={{
              // Path color
              path: {
                stroke: 'rgba(255, 255, 255,100)'
              },
              trail: {
                // Trail color
                stroke: '#637796'
              }
            }}
          />
        </div>
        <h2 className="text-center mt-3">{minutes}:{seconds}</h2>
        <div className="cancel-save">
          <button 
            className={this.state.controlButton === "record" || this.state.controlButton === "recording" ? "d-none" : "cancel-btn purp-btn mr-2"} 
            onClick={this.cancelRec}>
            DELETE
          </button>
          <CopyToClipboard
            text={"https://speek.link/s/" + this.state.docId}
            onCopy={() => this.setState({ copied: true })}
          >
            <button className={this.state.saved ? "save-btn purp-btn" : "d-none"} type="submit">
              COPY LINK
            </button>
          </CopyToClipboard>
        </div>
        {/* <ul>{listOfData}</ul> */}
        <div className={this.state.controlButton === "record" || this.state.controlButton === "recording" ? "wave-holder invisible" : "wave-holder"}>
          <div id="waveform" />
        </div>
        <p 
          className={this.state.controlButton === "record" || this.state.controlButton === "recording" ? "timestamp invisible" : "timestamp text-right"}>
          {this.state.progressTime} / {this.formatTime(this.state.recordTime)}
        </p>
      </div>
      
    );
  }
}

export default Home;
