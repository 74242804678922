import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

// change lines below with your own Firebase snippets!
var config = {
    apiKey: "AIzaSyARhgXQfrXwqMN21Wr_fPNjRHk2BL-QepI",
    authDomain: "speek-6b08c.firebaseapp.com",
    databaseURL: "https://speek-6b08c.firebaseio.com",
    projectId: "speek-6b08c",
    storageBucket: "speek-6b08c.appspot.com",
    messagingSenderId: "1075441923224",
    appId: "1:1075441923224:web:17d84764d16022b9"
};

const firebaseApp = firebase.initializeApp(config);
export default firebaseApp;