import React, {Component} from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import './index.css';
import Home from './Home';
import Speek from './Speek';
import * as serviceWorker from './serviceWorker';

class App extends Component {
    render() {
        return (
        <BrowserRouter>
            <Switch>
                <Route
                    exact={true}
                    path="/"
                    render={routeProps => {
                        return (
                            <Home />
                        );
                    }}
                />
                <Route
                    path="/s/:id"
                    component={Speek}
                />
            </Switch>
        </BrowserRouter>)};
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

export default App;
