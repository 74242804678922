import React, { Component } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import {Link} from 'react-router-dom';
import firebaseApp from './firebaseConfig';
import playButton from './playbutton.png';
import stopButton from './stopbutton.png';
import WaveSurfer from 'wavesurfer.js';
import WebAudioTrack from './WebAudioTrack';
import { Helmet } from "react-helmet";

var audioTrack;
var wavesurfer;

class Speek extends Component {
  
  constructor() {
    super();
    this.state = {
      speek: {},
      blob: null,
      circleProgress: 0,
      isLoading: true,
      progressTime: '0:00',
      recordTime: 0,
      playing: false,
      url: ''
    };
  }
  
  componentDidMount() {
    // this.loadingCircle();
    // btnStartRecording = document.getElementById('btn-start-recording');
    // btnStopRecording = document.getElementById('btn-stop-recording');
    // btnReleaseMicrophone = document.querySelector('#btn-release-microphone');
    // btnDownloadRecording = document.getElementById('btn-download-recording');
    // btnReleaseMicrophone = document.querySelector('#btn-release-microphone');
    // audio = document.querySelector('audio');
    audioTrack = new WebAudioTrack();
    const storageRef = firebaseApp.storage().ref();
    const ref = firebaseApp.firestore().collection('speeks').doc(this.props.match.params.id);
    
    ref.get().then((doc) => {
        if (doc.exists) {
            this.setState({
            speek: doc.data(),
            key: doc.id
            });
        
            const audioRef = storageRef.child('audio/' + this.props.match.params.id + '/clip.wav');
            
            audioRef.getDownloadURL().then((url) => {
                wavesurfer.load('https://cors-anywhere.herokuapp.com/' + url);
            }).catch(function (error) {
                switch (error.code) {
                    case 'storage/object-not-found':
                        console.log("File doesn't exist");
                        break;

                    case 'storage/unauthorized':
                        console.log("Unauthorized Download");
                        break;

                    case 'storage/canceled':
                        console.log("Unknown Storage Error");
                        break;

                    case 'storage/unknown':
                        console.log("Unknown Storage Error");
                    break;

                    default:
                        console.log("Error with Firebase");

                }
            });
             
        } else {
                console.log("No such document!");
            }
        });
        
        wavesurfer = WaveSurfer.create({
            container: document.querySelector('#waveform'),
            barWidth: 5,
            barHeight: 1,
            barGap: null,
            cursorColor: '#637796',
            waveColor: '#DBDBED',
            progressColor: '#637796'
        });
        
        wavesurfer.on('error', function (e) {
            console.warn(e);
        });

        // // Load audio from URL
        // wavesurfer.load(this.state.blob);

        // Play button
        // var button = document.querySelector('#btn-play');

        // button.addEventListener('click', wavesurfer.playPause.bind(wavesurfer));
        wavesurfer.on('audioprocess', () => {
            this.setState({ progressTime: this.formatTime(wavesurfer.getCurrentTime()) });
        });
        wavesurfer.on('play', () => {
            this.setState({playing: true});
        });
        wavesurfer.on('pause', () => {
            this.setState({playing: false});
        });
        wavesurfer.on('loading', (integer) => {
            this.setState({
                circleProgress: integer
            });
        });
        wavesurfer.on('ready', () => {
            this.setState({ isLoading: false });
        });
    }
    formatTime = (time) => {
        return [
            Math.floor((time % 3600) / 60), // minutes
            ('00' + Math.floor(time % 60)).slice(-2) // seconds
        ].join(':');
    };
    startRec() {
        audioTrack.startRecording(function () {
            console.log('start');
        });
    };

    stopRec() {
        audioTrack.stopRecording(function () {
            console.log('stopped');
        });
    };
    // loadingCircle = () => {
    //     this.loadingTimer = setInterval(() => {
    //         if (!this.state.isLoading) {
    //             clearInterval(this.loadingTimer);
    //         }
    //         this.setState({
    //             circleProgress: this.state.circleProgress + 50
    //         });
    //     }, 150);
    // }
    uploadRec = () => {
        let storageRef = firebaseApp.storage().ref();
        let recFile = audioTrack.getBlobSrc();
        let newBlob = audioTrack.getBlob();
        this.setState({blob:recFile});
        const soundRef = storageRef.child('audio/' + this.props.match.params.id + '/comments/user1.wav');
        soundRef.put(newBlob).then(function (snapshot) {
            console.log('Uploaded audio');
        });
    };

    controlButton() {
        if (!this.state.playing) {
            return <img className="player-icon" onClick={() => wavesurfer.playPause()} alt="play-button" id="btn-play" src={playButton} />;
         } else{
            return <img className="player-icon" onClick={() => wavesurfer.playPause()} alt="play-button" id="btn-play" src={stopButton} />
        }
    }

  render() {

    return (
      <div>
        <Helmet>
            <meta property="twitter:card" content="player" />
            <meta property="twitter:site" content="SPEEK" />
            <meta property="twitter:title" content="This is a sample title" />
            <meta name="twitter:description" content="This is a sample video. When you implement, make sure all links are secure." /> */}
            <meta name="twitter:image" content="https://yoursite.com/example.png" />
            <meta property="twitter:player" content={"https://firebasestorage.googleapis.com/v0/b/speek-6b08c.appspot.com/o/audio%2F" + this.props.match.params.id + "%2Fclip.wav?alt=media"} />
            {/* <meta property="twitter:player:width" content="480" />
            <meta property="twitter:player:height" content="480" /> */}
        </Helmet>
        <div className="hero-text">
            Speak whatever's on your mind.&nbsp;<br className="d-md-block" />
            <span className="d-sm-block d-md-none d-lg-block d-xl-block">
                Then share it to the world.
          </span>
        </div>
          {/* {this.state.speek.title}
          {this.state.speek.author} */}
          
          {this.controlButton()}
        <div className={this.state.isLoading ? "progress-circle" : "d-none"}>
            <CircularProgressbar
                value={this.state.circleProgress}
                strokeWidth={4}
                styles={{
                    // Path color
                    path: {
                        stroke: 'rgba(255, 255, 255,100)'
                    },
                    trail: {
                        // Trail color
                        stroke: '#637796'
                    },
                    width: '100px'
                }}
            />
        </div>
          
            <div className="cancel-save">
                <Link to="/">
                    <button className="save-btn purp-btn">
                        RESPOND
                    </button>
                </Link>
            </div>
          <div className="wave-holder">
            <div id="waveform" />
          </div>
        <p
                className={this.state.isLoading ? "invisible" : "timestamp text-right"}>
            {this.state.progressTime} / {this.formatTime(this.state.speek.length)}
        </p>
      </div>
      
    );
  }
}

export default Speek;
